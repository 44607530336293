<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <div class="pre-loader text-center" v-if="preLoader">
          <span class="loader"></span>
        </div>

        <div class="pt-1">
          <div class="row">
            <div class="col-md-3 col-1" v-if="windowWidth > 767">
              <button
                class="pl-1"
                style="background:#fff; border-radius: 5px; min-height: 35px; border: 1px solid #FAF0F0;"
                @click="sentToAmountView"
              >
                <img src="../../../assets/images/left_arrow.png" class="mb-1" height="12"> Back
              </button>
            </div>
            <div class="col-md-6 col-12 text-center">
              <div style="min-width:100%; display:flex; justify-content:center">
                <div
                  class="tab-div"
                  style="display:flex; justify-content:center;  align-items:center; max-width:400px; min-height:60px"
                >
                  <div
                    style="flex-basis:50%;  border-bottom:1px solid #0094FF"
                    class="pb-2 point-cursor"
                  >
                    <p>Vestlock</p>
                  </div>
                  <div style="flex-basis:50%" class="pb-2 point-cursor">
                    <p @click="gotoVestPortfolio">Vestlock Portfolio</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-1 text-right" v-if="windowWidth > 767">
              <button
                class="pl-3 mr-3"
                style="background:#fff; border-radius: 5px; min-height: 35px; border: 1px solid #FAF0F0;"
                @click="cancelVestLock"
              >
                Cancel
                <img src="../../../assets/images/x.png" class="mb-1 pr-1" height="10">
              </button>
            </div>
          </div>
        </div>

        <div style="display:flex; min-height:70vh; align-items:center" v-if="!preLoader">
          <div style="max-width:600px; margin:0 auto">
            <div class="row">
              <div class="col-md-12">
                <h5 class=" pb-2">Vestlock Summary</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-6" :class="{'col-12':windowWidth < 420}">
                <div class="vest-btn">
                  <div class="first-div">
                    <p style="color:#828282">Invest amount</p>
                    <h6 style="color:#000">
                      <b>
                        <span>&#8358;</span>
                        {{formatAmount(vestLockData.amountInvested)}}
                      </b>
                    </h6>
                  </div>

                  <div class="sec-div text-right point-cursor" @click="sentToAmountView">
                    <img src="../../../assets/images/edit.png" height="30">
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-6" :class="{'col-12  mt-2':windowWidth < 420}">
                <div class="vest-btn">
                  <div class="first-div">
                    <p style="color:#828282"> Upfront interest </p>
                    <h6 style="color:#0094FF">
                      <b>
                        <span>&#8358;</span>
                        {{formatAmount(vestLockData.calcInterest)}}
                      </b>
                    </h6>
                  </div>

                  <div class="sec-div text-right">
                    <img src="../../../assets/images/cashfaded.png" height="30">
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-6" :class="{'col-12':windowWidth < 420}">
                <div class="vest-btn mt-2">
                  <div class="first-div">
                    <p style="color:#828282">Interest</p>
                    <h6 style="color:#0094FF">
                      <b>{{vestLockData.calcRoi}} %</b>
                    </h6>
                  </div>

                  <div class="sec-div text-right">
                    <img src="../../../assets/images/percent.png" height="20">
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6" :class="{'col-12':windowWidth < 420}">
                <div class="vest-btn mt-2">
                  <div class="first-div">
                    <p style="color:#828282">Tenor</p>
                    <h6 style="color:#000">
                      <b>{{vestLockData.fixed_tenor}} <span  v-if="vestLockData.fixed_tenor > 1">  Months</span> <span v-else>Month</span></b>
                    </h6>
                  </div>

                  <div class="sec-div text-right">
                    <img src="../../../assets/images/cal.png" height="20">
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6" :class="{'col-12':windowWidth < 420}">
                <div class="vest-btn mt-2">
                  <div class="first-div">
                    <p style="color:#828282">Payout location</p>
                    <h6 style="color:#000">
                      <b>reQuid Wallet</b>
                    </h6>
                  </div>

                  <div class="sec-div text-right">
                    <img src="../../../assets/images/greywallet.png" height="20">
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6" :class="{'col-12':windowWidth < 420}">
                <div class="vest-btn mt-2">
                  <div class="first-div">
                    <p style="color:#828282">Matures</p>
                    <h6 style="color:#0094FF">
                      <b>{{ vestLockData.matures_at | formatDate}}</b>
                    </h6>
                  </div>

                  <div class="sec-div text-right">
                    <img src="../../../assets/images/cal.png" height="20">
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <div class="custom-control text-center custom-checkbox my-1 mt-4 mr-sm-2">
                    <input type="checkbox"   @click.stop="acceptTerms" class="custom-control-input" id="customControlInline2">
                    
                    <label
                      class="custom-control-label text-left"
                      for="customControlInline2"
                      style="cursor:pointer"
                    
                    ><span> I agree with the     <router-link to="/terms" target="_blank" style="display:inline;padding:0rem">  terms and conditions </router-link> of service for fulfiling the details that I chose in the Vestlock summary above.</span></label>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="text-center">
                  <button
                    class="vest-btn-2 mt-4"
                    :class="{'fade-elem' : isNotActive}"
                    style="min-width : 100%"
                    @click="purchaseVestLock"
                  >
                    <b>
                      <i class="fa fa-spin fa-spinner" v-if="vestLoader"></i>

                      
                      {{ vestLoader ? '':'Complete transaction' }}
                    </b>
                  </button>
                </div>
              </div>
            </div>
            <div class="container">
              <div
                class="display-div mb-4"
                v-if="windowWidth < 767"
                style="max-width: 450px; min-width:50%; margin:0 auto"
              >
                <div>
                  <button
                    class="mt-4 pl-1"
                    style="background:#fff; border-radius: 5px; min-height: 35px; border: 1px solid #FAF0F0;"
                    @click="sentToAmountView"
                  >
                    <img src="../../../assets/images/left_arrow.png" class="mb-1" height="12"> Back
                  </button>
                </div>
                <div class="text-right">
                  <button
                    class="mt-4 pl-1"
                    style="background:#fff; border-radius: 5px; min-height: 35px; border: 1px solid #FAF0F0;"
                    @click="cancelVestLock"
                  >
                    Cancel
                    <img src="../../../assets/images/x.png" class="mb-1 pr-1" height="10">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";
import http from "../../../http/index";
import api from "../../../apis/index";
import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";

export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {},
      isNotActive: true
    };
  },
  methods: {
    sendToMarketPlace() {
      this.$router.push("/market");
    },
   sentToAmountView() {
      this.$router.push("/vest/amount");
      this.$store.commit("auth/saveVestRouteVisited", "/vest/amount");
    },
    sentToInitView() {
      this.$router.push("/vest/init/sum");
    },
    acceptTerms() {
      this.isNotActive = !this.isNotActive;
    },
    sentToAmountView() {
      this.$router.push("/vest/amount");
      this.$store.commit("auth/saveVestRouteVisited", "/vest/amount");
    },
    gotoVestPortfolio() {
      this.$router.push("/vest/portfolio");
      this.$store.commit("auth/saveVestRouteVisited", "/vest/portfolio");
    },
    gotoFinalView() {
      this.$router.push("/vest/final");
      this.$store.commit("auth/saveVestRouteVisited", "/vest/final");
    },

    cancelVestLock() {
      swal({
        title: this.tran.transactions,
        text: this.tran.end_vestLock,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: this.tran.continue,
        confirmButtonText: this.tran.yes_cancel,
        animation: false,
        customClass: "animated fadeIn"
      }).then(result => {
        if (result.value) {
          this.$router.push("/vest/home");
        }
      });
    },
    purchaseVestLock() {
      let apiData = {
        product_id: this.vestLockData.id,
        amount: this.vestLockData.amountInvested
      };
      this.$store.commit("market/setVestLoader");

      http
        .post(api.purchaseVestProduct, apiData)

        .then(response => {
          this.$router.push("/vest/final");
          this.$store.commit("auth/saveVestRouteVisited", "/vest/final");
          this.$toast.success({
            title: this.tran.success,
            icon: require("../../../assets/images/successicon.png"),
            timeOut: 5000,
            message: "Vestlock purchased successfully",
            position: "top right"
          });

          this.$store.commit("market/stopVestLoader");
        })
        .catch(error => {
          if (error.response) {
            this.$store.commit(
              "domElements/saveNotificationMessage",
              error.response.data.message
            );
            this.$store.commit("market/stopVestLoader");
          }
        });
    }
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
      vestLockData: state => state.market.vestLockData,
      vestLoader: state => state.market.vestLoader
    }),

    returnAmountOfPenalty() {
      return this.penaltyAmount;
    }
  },
  mounted() {
    let heading = {
      main: this.tran.vest_lock
    };
    this.$store.commit(
      "auth/saveVestRouteVisitedAfterClickingPortfolio",
      this.$route.path
    );
    this.$store.commit("market/savePageHeading", heading);


    let vestData = localStorage.getItem("vestLockDataFromLocalStorage")
  vestData = JSON.parse(vestData)
      this.$store.commit("market/saveVestLockInvestmentData", vestData);

  },

  beforeCreate() {
    let userAmount = localStorage.getItem("investAmount");

    if (userAmount == "false") {
      this.$router.push("/vest/amount");
    } else {
   
    }

    this.$store.dispatch("market/getPortfolio");
    this.$store.commit("market/checkSearchObject", "portfolio");
  },

  beforeDestroy() {
    this.$store.commit("market/startPreloader");

    this.$store.commit("market/startIsFetching");
    this.$store.commit("market/removePageHeading");
  }
};
</script>

<style scoped>
.vest-btn {
  min-width: 100%;
}

.fade-elem {
  background: #fff;
  border: 2px solid #e0e0e0;
  opacity: 0.3;
}
</style>
